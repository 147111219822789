export const setCookie = (name, value, exdays) => {
	// create an expiry date with the chosen amount of days in the future
	const date = new Date();
	date.setTime(date.getTime() + exdays * 24 * 60 * 1000);
	let expires = "expires=" + date.toUTCString();
	document.cookie = name + "=" + value + ";" + expires + ";path=/";
};

export const getCookie = (cookieName) => {
	let name = cookieName + "=";

	// create an array of all cookies
	let decodedCookie = decodeURIComponent(document.cookie);
	let cookieArray = decodedCookie.split(";");
	// loop through the array and check for our searched name
	for (let i = 0; i < cookieArray.length; i++) {
		let cookie = cookieArray[i];

		while (cookie.charAt(0) == " ") {
			cookie = cookie.substring(1);
		}

		if (cookie.indexOf(name) == 0) {
			return cookie.substring(name.length, cookie.length);
		}
	}

	// we weren't able to find what we're looking for
	return null;
};

export const deleteAllCookies = () => {
	document.cookie.split(";").forEach((cookie) => {
		document.cookie = cookie
			.replace(/^ +/, "")
			.replace(
				/=.*/,
				"=;expires=" + new Date().toUTCString() + ";path=/"
			);
	});
};

export const isCookieSet = (cookieName) => {
	return document.cookie.match(/.*pz_cookie_consent=.*/);
};

export const removeScriptTag = (id) => {
  var scriptTag = document.getElementById(id);
  if (scriptTag) {
    scriptTag.remove();
  }
}
