function changeButton(type, element) {

    /*
     *  Clear current content
     */
    while (element.firstChild) {
        element.removeChild(element.lastChild);
    }

    /*
     *  Creates content corresponding to intended state
     */
    switch(type) {
        case "more":
            const more = document.createElement("p");
            more.classList.add("talk__button__text");
            more.appendChild(document.createTextNode("Mehr dazu"));

            var icon = document.createElement("img");
            icon.src = "../../../../assets/icons/plus.svg";
            icon.classList.add("talk__button__icon");

            element.appendChild(more);
            element.appendChild(icon);
            break;

        case "less":
            const less = document.createElement("p");
            less.classList.add("talk__button__text");
            less.appendChild(document.createTextNode("Weniger anzeigen"));

            var icon = document.createElement("img");
            icon.src = "../../../../assets/icons/minus.svg";
            icon.classList.add("talk__button__icon");

            element.appendChild(less);
            element.appendChild(icon);
            break;
    }
}

/*
 *  Shows/hides extra Text content and triggers button change
 */
function buttonListener(element) {
    var parent = element.parentElement;
    var talkContent = element.parentElement;
    var talk = talkContent.parentElement;
    var extraText = talk.querySelector("#extraText");

    if(element.classList.contains("talk__button--closed")) {
        changeButton("less", element);
        extraText.style.display = 'block';
        element.classList.remove("talk__button--closed");
        element.classList.add("talk__button--open");
    } else if(element.classList.contains("talk__button--open")) {
        changeButton("more", element);
        extraText.style.display = 'none';
        element.classList.remove("talk__button--open");
        element.classList.add("talk__button--closed");
    }
}

function videoListener(element) {
    if(!element.classList.contains("talk__video--triggered")) {
        if(window._paq) {
          window._paq.push(['trackEvent', 'Video', 'clicked', 'video played']);
        }

        const video = element.firstElementChild.lastElementChild;
        const overlay = element.firstElementChild.querySelectorAll("img");
        const caption = element

        overlay.forEach(element => element.style.zIndex = -1);
        element.style.cursor = 'auto';
        video.play();
        element.classList.add("talk__video--triggered");
    }
}

const talk = () => {
    window.addEventListener('load', () => {
        const buttons = document.querySelectorAll('.talk__button');
        buttons.forEach(button => button.addEventListener('click', () => buttonListener(button)));

        const videos = document.querySelectorAll('.talk__video');
        videos.forEach(video => video.addEventListener('click', () => videoListener(video)));
    })
}

export default talk;
