import {
	isCookieSet,
	setCookie,
	getCookie,
	deleteAllCookies,
  removeScriptTag,
} from "./cookies";
import parseServices from "./tracking";

const services = parseServices();
const CONSENT_COOKIE_NAME = "pz_cookie_consent";
const bannerElement = document.getElementById("consent-banner");

const initializeService = (service) => {
	switch (service) {
		case "Matomo Tag Manager":
			window._paq = window._paq || [];
      var _mtm = (window._mtm = window._mtm || []);
      window._paq.push(["rememberCookieConsentGiven"]);
      _mtm.push({ event: "consentGiven" });
			break;

		case "Matomo Analytics":
      var _paq = (window._paq = window._paq || []);
      _paq.push(["rememberCookieConsentGiven"]);
			break;

		case "Facebook Pixel":
      var serviceData = services.find(
				(element) => element.type === service
			);

			if(serviceData) {
				!function(f,b,e,v,n,t,s) {
				if(f.fbq)return;
				n=f.fbq=function(){
					n.callMethod?
					n.callMethod.apply(n,arguments):n.queue.push(arguments)};
					if(!f._fbq)f._fbq=n;
					n.push=n;
					n.loaded=!0;
					n.version='2.0';
					n.queue=[];
					t=b.createElement(e);
					t.async=!0;
					t.src=v;
					t.id="facebook-pixel"
					s=b.getElementsByTagName(e)[0];
					s.parentNode.insertBefore(t,s)
				}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');
				fbq('init', serviceData.pixelId);
				fbq('track', 'PageView');
			}
			break;

		case "Google Tag Manager":
      var serviceData = services.find(
				(element) => element.type === service
			);

			if(serviceData) {
				(function () {
				var d = document,
					g = d.createElement("script"),
					s = d.getElementsByTagName("script")[0];
				g.async = true;
				g.src = `https://www.googletagmanager.com/gtag/js?id=${serviceData.gtag}`;
				s.parentNode.insertBefore(g, s);
				g.id = "google-tag-manager";

				window.dataLayer = window.dataLayer || [];
				function gtag(){dataLayer.push(arguments);}
				gtag('js', new Date());

				gtag('config', serviceData.gtag);
				})();
			}
			break;

		case "LinkedIn Insight Tag":
      var serviceData = services.find(
				(element) => element.type === service
			);

			if(serviceData) {
				_linkedin_partner_id = serviceData.linkedinPartnerId;
				window._linkedin_data_partner_ids =
				window._linkedin_data_partner_ids || [];
				window._linkedin_data_partner_ids.push(_linkedin_partner_id);
				(function (l) {
				if (!l) {
					window.lintrk = function (a, b) {
					window.lintrk.q.push([a, b]);
					};
					window.lintrk.q = [];
				}
				var s = document.getElementsByTagName("script")[0];

        const scriptTag = document.getElementById('linkedin-insight-tag')
        if(!scriptTag) {
          var b = document.createElement("script");
          b.type = "text/javascript";
          b.async = true;
          b.id = "linkedin-insight-tag";
          b.src =
            "https://snap.licdn.com/li.lms-analytics/insight.min.js";
          s.parentNode.insertBefore(b, s);
        }

				})(window.lintrk);
			}
			break;

		default:
			break;
	}
};

const setupCookielessTrackers = () => {
  // setup matomo for cookieless tracking
  const matomoTagManager = services.find(
    (element) => element.type === 'Matomo Tag Manager'
  );

  if(matomoTagManager) {
      const scriptTag = document.getElementById('matomo-tag-manager')
      if(!scriptTag) {
        var _mtm = (window._mtm = window._mtm || []);
        _mtm.push({
          "mtm.startTime": new Date().getTime(),
          event: "mtm.Start",
        });
        var d = document,
          g = d.createElement("script"),
          s = d.getElementsByTagName("script")[0];
        g.async = true;
        g.src = `${matomoTagManager.matomoUrl}/js/container_${matomoTagManager.matomoContainerId}.js`;
        s.parentNode.insertBefore(g, s);
        g.id = "matomo-tag-manager";
        window._paq = window._paq || [];
        window._paq.push(['requireCookieConsent']);
      }

  }

  const matomoAnalytics = services.find(
    (element) => element.type === 'Matomo Analytics'
  );

  if(matomoAnalytics) {
    const scriptTag = document.getElementById('matomo-analytics')
    if(!scriptTag) {
      var _paq = (window._paq = window._paq || []);
      _paq.push(['requireCookieConsent']);
      /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
      _paq.push(["trackPageView"]);
      _paq.push(["enableLinkTracking"]);
      (function () {
      var u = matomoAnalytics.matomoUrl;
      _paq.push(["setTrackerUrl", u + "matomo.php"]);
      _paq.push(["setSiteId", matomoAnalytics.matomoSiteId]);
      var d = document,
        g = d.createElement("script"),
        s = d.getElementsByTagName("script")[0];
      g.async = true;
      g.src = u + "matomo.js";
      s.parentNode.insertBefore(g, s);
      g.id = "matomo-analytics";
      })();
    }
  }
}

const handleSelection = (
	consentedServices,
	declinedServices,
	timeout = false
) => {
	// prevent simplebar scrollbar from glitching out on consent banner closing
	turnOffTransition();

	// cleanup any leftovers of declined services
	declinedServices.forEach((service) => {
		switch (service) {
			case "Matomo Tag Manager":
        var _mtm = (window._mtm = window._mtm || []);
        var _paq = (window._paq = window._paq || []);
        _paq.push(['forgetCookieConsentGiven']);
        _mtm.push({ event: "consentDenied" });
				break;

			case "Matomo Analytics":
        var _paq = (window._paq = window._paq || []);
        _paq.push(['forgetCookieConsentGiven']);
        break;

			case "Facebook Pixel":
        removeScriptTag("facebook-pixel");
				break;

			case "Google Tag Manager":
        removeScriptTag("google-tag-manager");
				break;

      case "LinkedIn Insight Tag":
        removeScriptTag("linkedin-insight-tag")
        break;

			default:
				break;
		}
	});

  // delete all cookies to prevent formerly consented but now declined services to keep tracking
	deleteAllCookies();

  // execute opt-in code of consented services
	consentedServices.forEach((service) => {
		initializeService(service);
	});

	setCookie(CONSENT_COOKIE_NAME, consentedServices.join(","), 365);

	if (timeout) {
		setTimeout(() => {
			bannerElement.classList.toggle("consent-banner--visible");
		}, "350");
	} else {
		bannerElement.classList.toggle("consent-banner--visible");
	}
};

/**
 * Because of the forced timeout that allows the toggles to get flipped when the consent banner closes
 * the transition property on the simple-bar:before element can make the scroll bar stay floating for a tiny bit
 * after the consent banner disappeared. To prevent this we need to manually turn the transition on or off.
 */
const turnOffTransition = () => {
	document.body.style.overflow = "auto";
	const scrollBars = document.querySelectorAll(".simplebar-scrollbar");
	scrollBars.forEach((scrollBar) =>
		scrollBar.classList.add("disable-transition")
	);
};

const turnOnTransition = () => {
	document.body.style.overflow = "hidden";
	const scrollBars = document.querySelectorAll(".simplebar-scrollbar");
	scrollBars.forEach((scrollBar) =>
		scrollBar.classList.remove("disable-transition")
	);
};

const getSwitchValues = (switches) => {
	var values = [];
	switches.forEach((element) => (values = [...values, element.checked]));
	return values;
};

const onlyUnique = (value, index, self) => {
	return self.indexOf(value) === index;
};

const consentBanner = () => {
	const form = document.getElementById("consent-form");

	if (form) {
		const categories = document.querySelectorAll(".consent-banner__item");
		const bundledServiceSwitches = form.querySelectorAll(".service-switch");
		const declineAllButton = document.getElementById("decline-all");
		const saveButton = document.getElementById("save-selection");
		const acceptAllButton = document.getElementById("accept-all");
		const settingsButton = document.getElementById(
			"cookie-settings"
		);

		settingsButton.onclick = () => {
			turnOnTransition();
			bannerElement.classList.toggle("consent-banner--visible");
		};

		saveButton.addEventListener("click", () => {
			var acceptedServices = [];
			var declinedServices = [];

			bundledServiceSwitches.forEach((element) => {
				const service = services.find(
					(service) => service.type === element.id
				);

				if (element.checked) {
					acceptedServices.push(service.type);
				} else {
					declinedServices.push(service.type);
				}
			});

			handleSelection(acceptedServices, declinedServices);
		});

		declineAllButton.addEventListener("click", () => {
			bundledServiceSwitches.forEach(
				(element) => (element.checked = false)
			);
			form.querySelectorAll(".category-switch").forEach((element) => {
				if (element.id !== "essentials") {
					element.checked = false;
				}
			});
			handleSelection(
				[],
				services.map((service) => service.type),
				true
			);
		});

		acceptAllButton.addEventListener("click", () => {
			bundledServiceSwitches.forEach(
				(element) => (element.checked = true)
			);
			form.querySelectorAll(".category-switch").forEach(
				(element) => (element.checked = true)
			);
			handleSelection(
				services.map((service) => service.type),
				[],
				true
			);
		});

    // support other links to the dse settings
    window.addEventListener('load', () => {
      const settingsLinks = document.querySelectorAll('.cookie-settings')
      settingsLinks.forEach(link => {
        link.addEventListener('click', () => {
          turnOnTransition();
		    	bannerElement.classList.toggle("consent-banner--visible");
        })
      })
    })

		if (!isCookieSet(CONSENT_COOKIE_NAME)) {
			turnOnTransition();
			bannerElement.classList.toggle("consent-banner--visible");
		} else {
			// setup services that have previously been consented
			var consentedServices = getCookie(CONSENT_COOKIE_NAME).split(",");
			consentedServices.forEach((service) => initializeService(service));

      window.addEventListener('load', () => {
        var consentedServices = getCookie(CONSENT_COOKIE_NAME).split(",");
			  consentedServices.forEach((service) => initializeService(service));
      })
		}

		// adjust switch values based on current selection
		categories.forEach((category) => {
			const categorySwitch = category.querySelector(".category-switch");
			const serviceSwitches =
				category.querySelectorAll(".service-switch");

			// make all of a category's switches follow the parent
			categorySwitch.addEventListener("change", () => {
				serviceSwitches.forEach(
					(element) => (element.checked = categorySwitch.checked)
				);
			});

			serviceSwitches.forEach((element) => {
				element.addEventListener("change", () => {
					// setting one of the category's switches to off demands the category to be off to
					if (
						categorySwitch.checked === true &&
						element.checked === false
					) {
						categorySwitch.checked = false;
					}

					/**
					 * If one of the service switches gets changed we need to check if all of the categry's switches
					 * have the same value. If they do we need to adjust the category switch accordingly.
					 */
					if (
						getSwitchValues(serviceSwitches).filter(onlyUnique)
							.length == 1
					) {
						categorySwitch.checked = element.checked;
					}
				});
			});
		});
	}

  window.addEventListener('load', () => {
    setupCookielessTrackers()
  })
};

export default consentBanner;
