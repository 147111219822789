// parses all available tracking services and their data into a handy object
const parseServices = () => {
	var servicesWrapper = document.getElementById("tracking-config");
	var allServices = [];

	if (servicesWrapper) {
		for (var i = 0; i < servicesWrapper.children.length; i++) {
			element = servicesWrapper.children[i];

			allServices.push({
				type: element.dataset.type,
				category: element.dataset.category,
				matomoUrl: element.dataset.matomoUrl,
				matomoContainerId: element.dataset.matomoContainerId,
				matomoSiteId: element.dataset.matomoSiteId,
				linkedinPartnerId: element.dataset.linkedinPartnerId,
        pixelId: element.dataset.pixelId,
			});
		}

		return allServices;
	}
};

export default parseServices;

