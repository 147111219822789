import textWithVideo from '../../site/plugins/text-with-video/assets/textWithVideo';
import contactForm from '../../site/plugins/contact-form/assets/contactForm';
import talk from '../../site/plugins/talk/assets/talk';
import consentBanner from './consentBanner';
import "simplebar"; // or "import SimpleBar from 'simplebar';" if you want to use it manually.

// needed for simplebar
import ResizeObserver from 'resize-observer-polyfill';
window.ResizeObserver = ResizeObserver;

/**
 *  Load components
 * */
textWithVideo();
contactForm();
talk();
consentBanner();
