function videoBoxClickListener() {
  const wrapper = document.getElementById("video-wrapper");
  const gradient = wrapper.firstElementChild;
  const video = wrapper.lastElementChild;
  const thumbnail = wrapper.querySelector("img");

  thumbnail.style.display = 'none';
  gradient.remove();
  video.play();
}

function remToPx(number) {
  if (window.innerWidth < 1024) {
    return number * 19;
  } else {
    return number * 16;
  }
}

/**
* Positions video box to not leave too much empty real estate space below it.
*/
function rearrangeVideoBox() {
  const twv = document.getElementById("twv");
  if(twv) {
    const twv__vBox = twv.querySelector(".twv__video-box")

    if (window.innerWidth > 768 && ((twv.offsetHeight - twv__vBox.offsetHeight) > (twv__vBox.offsetHeight / 2))) {
      var newTop = (twv.offsetHeight - twv__vBox.offsetHeight) / 2;
      if (newTop >= 0) {
        twv__vBox.style.borderTopLeftRadius = '1rem';
        twv__vBox.style.borderBottomRightRadius = '0rem';
        twv__vBox.style.top = (twv.offsetHeight - twv__vBox.offsetHeight) / 2 + 'px';
      } else {
        twv__vBox.style.borderTopLeftRadius = '0rem';
        twv__vBox.style.top = 0 + 'px';
      }
    } else {
      twv__vBox.style.top = 0 + 'px';

      if (window.innerWidth > 768) {
        twv__vBox.style.borderTopLeftRadius = '0rem';
      } else {
        twv__vBox.style.borderTopLeftRadius = '1rem';
        twv__vBox.style.borderBottomRightRadius = '1rem';
      }

      if (window.innerWidth >= 1400) {
        twv__vBox.style.borderBottomRightRadius = '1rem';
      }
    }
  }

}

const TextWithVideo = () => {
  const headerHeight = 0;

  window.addEventListener('load', () => {
    var header = document.getElementById("header");

    headerHeight = header.clientHeight;

    rearrangeVideoBox(headerHeight);
    const videoBox = document.querySelector(".twv__video-box");
    if(videoBox) {
      videoBox.addEventListener('click', () => videoBoxClickListener());
    }
  });

  window.addEventListener('resize', () => {
    header = document.getElementById("header");
    headerHeight = header.clientHeight;
    rearrangeVideoBox()
  });
};

export default TextWithVideo;
