function appendErrorImage(element) {
    var img = document.createElement("img");
    img.src = "../../../../assets/icons/exclamation-tringle.svg"
    img.classList.add("error-icon");
    element.parentElement.appendChild(img);
}

function handleTextInputError(element, type) {
    element.classList.add('input--error');
    appendErrorImage(element);
}

// Removes error styling when justified
const valueLengthCheck = function(element, minLength, type, e) {
    switch(type) {
        case 'textarea':
            if(e.target.value.length > (minLength - 1)) {
                if(element.classList.contains("textarea--error")) {
                    element.classList.remove("textarea--error");
                    element.parentElement.querySelector("img").remove();
                }
            }
            break;

        case 'input':
            if( element.value.length > (minLength - 1) ) {
                if(element.classList.contains("input--error")) {
                    element.classList.remove("input--error");
                    element.parentElement.querySelector("img").remove();
                }
            }
            break;
    }
}

const emailValueCheck = function(element, minLength) {
    var passed = true;

    if(    element.value.length > (minLength - 1)
        || !element.value.includes('@')
        || !element.value.includes('.')
    ) {
        passed = false;
    }

    if(passed) {
        if(element.classList.contains("input--error")) {
            element.classList.remove("input--error");
            element.parentElement.querySelector("img").remove();
        }
    }
}

function getErrorStateIndex(formElement) {
    switch(formElement) {
        case 'name':
            return 0;
        case 'email':
            return 1;
        case 'phone':
            return 2;
        case 'postalCode':
            return 3;
        case 'accepted':
          return 4;
    }
}

function validate() {
    const message = document.getElementById("message");
    const name = document.getElementById("name");
    const email = document.getElementById("email");
    const phone = document.getElementById("phone");
    const postalCode = document.getElementById("postalCode");

    // conditions
    const minTextareaLength = 10;
    const minNameLength = 2;
    const minEmailLength = 5;
    const minPhoneLength = 4;
    const minPostalCodeLength = 5;

    var errorState = [false, false, false, false ];

    // Prevents submit and adds error styling if needed

    if( name.value.length < minNameLength ) {
        handleTextInputError(name);
        name.addEventListener('input', () => valueLengthCheck(name, minNameLength, 'input'), false);
        errorState[getErrorStateIndex('name')] = false;
    } else { errorState[getErrorStateIndex('name')] = true; }

    if(    !email.value.includes('@')
        || !email.value.includes('.')
        || (email.value.length < minEmailLength)
    ) {
        handleTextInputError(email);
        email.addEventListener('input', () => {
            emailValueCheck(email);
        }, false);
        errorState[getErrorStateIndex('email')] = false;
    } else { errorState[getErrorStateIndex('email')] = true; }

    if( phone.value.length < minPhoneLength ) {
        handleTextInputError(phone);
        phone.addEventListener('input', () => valueLengthCheck(phone, minPhoneLength, 'input'), false);
        errorState[getErrorStateIndex('phone')] = false;
    } else { errorState[getErrorStateIndex('phone')] = true; }

    if( postalCode.value.length < minPostalCodeLength ) {
        handleTextInputError(postalCode);
        postalCode.addEventListener('input', () => valueLengthCheck(postalCode, minPostalCodeLength, 'input'), false);
        errorState[getErrorStateIndex('postalCode')] = false;
    } else { errorState[getErrorStateIndex('postalCode')] = true; }

    const checkbox = document.getElementById('accepted');
    if(!checkbox.checked) {
      checkbox.parentElement.classList.add('validation-failed')
      errorState[getErrorStateIndex('accepted')] = false;
    }

    if(errorState.includes(false)) {
        switch(errorState.indexOf(false)) {
            case 0:
                document.querySelector('.name').scrollIntoView();
                break;
            case 1:
                document.querySelector('.email').scrollIntoView();
                break;
            case 2:
                document.querySelector('.phone').scrollIntoView();
                break;
            case 3:
                document.querySelector('.postalCode').scrollIntoView();
                break;
            case 4:
              document.querySelector('#accepted').scrollIntoView();
              break;
        }
        return false;
    } else {
        document.querySelector(".contact-form").scrollIntoView();
        return true;
    }
}

const contactForm = () => {
    const form = document.getElementById('form')
    if(form) {
      window.addEventListener('load', () => {
        form.onsubmit = (event) => {
            if(validate() === true) {
                window._mtm && window._mtm.push({ 'event': 'leadSubmitted' });
                window.lintrk && window.lintrk('track', { conversion_id: 11715260 });
                return true;
            } else {
                return false;
            }
        }
    })
    }
}

export default contactForm;
